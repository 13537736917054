import { Middleware } from '@reduxjs/toolkit';
import { parseISO, format } from 'date-fns';

const DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS";

const dateMiddleware: Middleware = ({ getState }) => next => action => {
  const state = getState();
  const actionWithDates = replaceDates(action, state);
  return next(actionWithDates);
};

const replaceDates = (obj: any, state: any) => {
  const newObj: any = Array.isArray(obj) ? [] : {};
  for (let key in obj) {
    const value = obj[key];
    if (value instanceof Date) {
      newObj[key] = format(value, DATE_FORMAT);
    } else if (typeof value === 'object' && value !== null) {
      newObj[key] = replaceDates(value, state);
    } else {
      newObj[key] = value;
    }
  }
  return newObj;
};

const parseDates = (obj: any, state: any) => {
  const newObj: any = Array.isArray(obj) ? [] : {};
  for (let key in obj) {
    const value = obj[key];
    if (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/)) {
      newObj[key] = parseISO(value);
    } else if (typeof value === 'object' && value !== null) {
      newObj[key] = parseDates(value, state);
    } else {
      newObj[key] = value;
    }
  }
  return newObj;
};

export { dateMiddleware, parseDates };