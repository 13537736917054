import { RootState } from '@/store/store.config';
import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { defaultDeliveryOrderSearchFields, DeliveryOrderListParams } from '../types/deliveryOrder.search.schema';
import { ProductVariant } from '@/features/Catalog/types/product.model';
import { memoize } from 'proxy-memoize';
import { DeliveryOrder } from '../types/deliveryOrder.model';
import { defaultDeliveryOrderCreateFields, DeliveryOrderDto } from '../types/deliveryOrder.create.schema';

export type TDeliveryOrderPhase = 'show' | 'edit' | 'new'
export type TDeliveryOrderFormPhase = 'initializing' | 'idling' | 'modifying'

export interface DeliveryOrderState {
  deliveryOrderProductVariants: EntityState<ProductVariant>,
  deliveryOrderProcessingForm?: (DeliveryOrder & DeliveryOrderDto)
  filter: DeliveryOrderListParams;
  deliveryOrderPhase: TDeliveryOrderPhase,
  deliveryOrderFormPhase: TDeliveryOrderFormPhase,
  getDeliveryOrder: (() => DeliveryOrder & DeliveryOrderDto)
}

export const deliveryOrderProductVariantAdapter = createEntityAdapter<ProductVariant>({
  selectId: product => {
    return product.id
  },
});

export const deliveryOrderInitialState: DeliveryOrderState = ({
  deliveryOrderProductVariants: deliveryOrderProductVariantAdapter.getInitialState(),
  deliveryOrderProcessingForm: undefined,
  filter: {
    ...defaultDeliveryOrderSearchFields,
    page: 1,
    per_page: 25,
  },
  deliveryOrderPhase: 'new',
  deliveryOrderFormPhase: "initializing",
  getDeliveryOrder: () => defaultDeliveryOrderCreateFields,
});

const deliveryOrderSlice = createSlice({
  name: 'deliveryOrder',
  initialState: deliveryOrderInitialState,
  reducers: {
    productVariantsReceived(state, action: PayloadAction<{ products: ProductVariant[], pharse?: TDeliveryOrderFormPhase }>) {
      deliveryOrderProductVariantAdapter.setAll(state.deliveryOrderProductVariants, action.payload.products)
      state.deliveryOrderFormPhase = action.payload.pharse || deliveryOrderInitialState.deliveryOrderFormPhase
    },
    productVariantsRemoved(state, action: PayloadAction<ProductVariant["id"][]>) {
      deliveryOrderProductVariantAdapter.removeMany(state.deliveryOrderProductVariants, action.payload)
    },
    setDeliveryOrderProcessingForm(state, action: PayloadAction<(DeliveryOrder & DeliveryOrderDto | undefined)>) {
      state.deliveryOrderProcessingForm = action.payload
    },
    setDeliveryOrderFormPhase(state, action: PayloadAction<TDeliveryOrderFormPhase>) {
      state.deliveryOrderFormPhase = action.payload
    },
    setFilter(state, action: PayloadAction<DeliveryOrderListParams>) {
      state.filter = {
        ...state.filter,
        ...action.payload
      }
    },
    setGetDeliveryOrder(state, action: PayloadAction<() => DeliveryOrder & DeliveryOrderDto>) {
      state.getDeliveryOrder = action.payload
    },
    setDeliveryOrderPhase(state, action: PayloadAction<TDeliveryOrderPhase>) {
      state.deliveryOrderPhase = action.payload
    },
    resetFilter(state, action: PayloadAction<Partial<DeliveryOrderListParams> | undefined>) {
      const newState = action?.payload
      state.filter = {
        ...state.filter,
        ...deliveryOrderInitialState.filter,
        ...newState
      }
    },
    resetAdvanceFilter(state, action: PayloadAction<Partial<DeliveryOrderListParams> | undefined>) {
      const newState = action?.payload
      state.filter = {
        ...state.filter,
        ...deliveryOrderInitialState.filter,
        ...newState,
        query_term: state.filter.query_term,
      }
    },
  },
});

export const {
  selectById: selectProductVariantById,
  selectIds: selectProductVariantIds,
  selectEntities: selectProductVariantEntities,
  selectAll: selectProductVariantList,
  selectTotal: selectTotalProductVariants
} = deliveryOrderProductVariantAdapter.getSelectors((state: RootState) => state.deliveryOrder.deliveryOrderProductVariants);

// Actions
export const deliveryOrderActions = deliveryOrderSlice.actions;

export const selectDeliveryOrderFilter = memoize((state: RootState): DeliveryOrderListParams => state.deliveryOrder.filter)
export const selectDeliveryOrderPhase = memoize((state: RootState): TDeliveryOrderPhase => state.deliveryOrder.deliveryOrderPhase)
export const selectIsShowPhase = memoize((state: RootState): boolean => state.deliveryOrder.deliveryOrderPhase === 'show')
export const selectIsEditPhase = memoize((state: RootState): boolean => state.deliveryOrder.deliveryOrderPhase === 'edit')
export const selectIsCreatePhase = memoize((state: RootState): boolean => state.deliveryOrder.deliveryOrderPhase === 'new')
export const selectDeliveryOrderFromPhase = memoize((state: RootState): TDeliveryOrderFormPhase => state.deliveryOrder.deliveryOrderFormPhase)
export const selectDeliveryOrderProcessingForm = memoize((state: RootState): (DeliveryOrder & DeliveryOrderDto) | undefined => state.deliveryOrder?.deliveryOrderProcessingForm)
export const selectGetDeliveryOrder = memoize((state: RootState):  (() => DeliveryOrder & DeliveryOrderDto) => state.deliveryOrder.getDeliveryOrder)

export const deliveryOrderSelectedProductSkuListSelector = createSelector(selectProductVariantList, (list) => list?.map(prod => prod.sku) || [])

// Reducer
const deliveryOrderReducer = deliveryOrderSlice.reducer;
export default deliveryOrderReducer;
