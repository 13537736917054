import { CloseRounded } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";

const CloseButton = ({
  closeToast,
}: {
  closeToast: (e: React.MouseEvent<HTMLElement>) => void;
}) => {
  return (
    <Box className="close-box">
      <IconButton size="small" onClick={closeToast}>
        <CloseRounded fontSize="small" />
      </IconButton>
    </Box>
  );
};

export default CloseButton;
