'use client'
import BigNumber from 'bignumber.js';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useLayoutEffect } from 'react'

const AdjustHeightOnMobile = () => {

  const handleResize = useCallback(
    (event?: WindowEventMap['resize']) => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    []
  );

  const handleResizeDebounce = useCallback(debounce(handleResize, 500), [handleResize]);

  useLayoutEffect(() => {
    handleResizeDebounce();
    window.addEventListener('resize', handleResizeDebounce);

    return () => {
      window.removeEventListener('resize', handleResizeDebounce);
    };
  }, [handleResizeDebounce]);

  return null
}

export default AdjustHeightOnMobile
