import { RootState } from '@/store/store.config';
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { Activity, TActivityType } from '../types/activity.model';
import { ActivityListParams } from '../types/activity.search.schema';

export interface ActivityState extends EntityState<Activity> {
  filter: Record<TActivityType, ActivityListParams>;
}

export const activityAdapter = createEntityAdapter<Activity>({
  selectId: activity => activity.id,
});

export const activityInitialState: ActivityState = activityAdapter.getInitialState({
  filter: {
    all: {
      activity_type: 'all',
      query_term: '',
      start_date: null,
      end_date: null,
      audit_action: null,
      type: null,
      staff: null,
      page: 1,
      per_page: 25,
    },
    quotation: {
      activity_type: 'quotation',
      query_term: '',
      start_date: null,
      end_date: null,
      audit_action: null,
      type: null,
      staff: null,
      page: 1,
      per_page: 25,
    },
    invoice: {
      activity_type: 'invoice',
      query_term: '',
      start_date: null,
      end_date: null,
      audit_action: null,
      type: null,
      staff: null,
      page: 1,
      per_page: 25,
    },
    delivery_order: {
      activity_type: 'delivery_order',
      query_term: '',
      start_date: null,
      end_date: null,
      audit_action: null,
      type: null,
      staff: null,
      page: 1,
      per_page: 25,
    }
  },
});

const activitySlice = createSlice({
  name: 'activity',
  initialState: activityInitialState,
  reducers: {
    setFilter(state, action: PayloadAction<ActivityListParams>) {
      const activityType = action.payload?.activity_type || 'quotation'
      if (activityType) {
        state.filter[activityType] = {
          ...state.filter[activityType],
          ...(action?.payload || {})
        }
      }
    },
    resetFilter(state, action: PayloadAction<Record<TActivityType, ActivityListParams> | undefined>) {
      const payload = action?.payload || {}
      state.filter = {
        ...activityInitialState.filter,
        ...payload
      }
    },
    resetAdvanceFilter(state, action: PayloadAction<Partial<ActivityListParams>>) {
      const activityType = action.payload?.activity_type || 'quotation'
      if (activityType) {
        state.filter[activityType] = {
          ...state.filter[activityType],
          ...activityInitialState.filter[activityType],
          ...action?.payload,
          query_term: state.filter[activityType].query_term
        }
      }
    },
  },
});

// Actions
export const activityActions = activitySlice.actions;

export const selectActivityFilter = (state: RootState, type: TActivityType) => state.activity.filter[type];

// Reducer
const activityReducer = activitySlice.reducer;
export default activityReducer;
