export interface PaginationResponse {
  self: string,
  first: string
  prev: string,
  next: string,
  last: string
}

export interface ResponseStatus {
  status?: number;
}

export interface ResponseMessage extends ResponseStatus {
  message?: string
}

export interface RecordData<T, T2 = string> extends ResponseStatus, DataAndRelationships {
  id: string;
  type: T2;
  attributes: T;
}

export interface PageMetaResponse extends ResponseStatus {
  total_pages: number;
  total_count: number;
}

export interface ListResponse<T> extends ResponseStatus {
  data: RecordData<T>[],
  meta: PageMetaResponse,
  links: PaginationResponse
}

export interface RelationshipStruc {
  id: string,
  type: string
}

export interface RelationshipItem<T = unknown> {
  data: T extends unknown | null ? RelationshipStruc : RelationshipStruc[]
}

export interface DataAndRelationships<T = unknown> {
  relationships?: T extends unknown | null ? Record<string, RelationshipItem> : T
}

export interface ListResponseV2<Tdata, Tinclude = unknown, Tmeta = any> extends ResponseStatus {
  data: (Tdata & DataAndRelationships)[],
  meta: PageMetaResponse & Tmeta,
  links: PaginationResponse,
  included?: Tinclude[]
}

export interface ListResponseV2NoPagy<Tdata, Tinclude = unknown> extends Omit<ListResponseV2<Tdata, Tinclude>, 'meta' | 'links'> {

}

export interface AuthResponse<Tdata> extends ResponseStatus {
  data: Tdata;
}

export interface Response<Tdata, Tinclude = any, Tmeta = any> extends ResponseStatus {
  data: Tdata & DataAndRelationships;
  included?: Tinclude[],
  meta?: Tmeta
}

export interface SinglePayload<Tdata, Tinclude = any, Tmeta = any> {
  data: Tdata & DataAndRelationships;
  included?: Tinclude[],
  meta?: Tmeta
}

export interface DestroyResponse extends ResponseStatus {
  data: {
    success: boolean,
  },
}

export interface ErrorSource {
  pointer: string,
  parameter: string
}

export interface ErrorDetail {
  id: number,
  status: string | number,
  title: string,
  detail: string,
  source: ErrorSource
}

export interface ErrorResponse extends ResponseStatus {
  status: number,
  message: string,
  errors?: ErrorDetail[]
  source?: ErrorSource,
  rawError?: string,
  skipLogger?: boolean,
}

export function isErrorResponse(error?: any): error is ErrorResponse {
  return !!error?.status
}

export interface ListParams<T = number> {
  page: T extends number ? number : T;
  per_page?: T extends number ? number : T;
}

type ReportKeyFormat = `${string}-${string}`;

export interface ReportParams extends Record<ReportKeyFormat, any> {
  timezone: `${string}/${string}`
  "start-date": string | null,
  "end-date": string | null,
}

export type TExportType = "pdf" | "csv"

export interface ExportParams {
  type: TExportType
}
