//GET
export const GET_DELIVERY_ORDER_LIST = `/api/delivery_order`
export const GET_EXPORT_DELIVERY_ORDER = `/api/delivery_order/export`
export const GET_DELIVERY = `/api/delivery_order`
export const GET_LINE_DELIVERY_ORDERS_PATH = `/api/delivery/[delivery_order_id]/line_items`
export const GET_DELIVERY_ORDER_LIST_OF_INVOICE_PATH = `/api/invoice/[invoice_id]/delivery_orders`
export const GET_EXPORT_DELIVERY_ORDER_LIST_OF_INVOICE_PATH = `/api/invoice/[invoice_id]/delivery_orders/export`
export const GET_NEW_DELIVERY_ORDER_OF_INVOICE_PATH = `/api/invoice/[invoice_id]/delivery_orders/new`
export const GET_DELIVERY_ORDER_OF_INVOICE_PATH = `/api/invoice/[invoice_id]/delivery_orders/[delivery_order_id]`
export const GET_DELIVERY_ORDER_AUDIT_HISTORY_LIST_OF_INVOICE_PATH = `/api/invoice/[invoice_id]/delivery_orders/[delivery_order_id]/histories`

//POST
export const POST_CREATE_DELIVERY_ORDER = GET_DELIVERY_ORDER_LIST_OF_INVOICE_PATH
export const POST_CREATE_DELIVERY_ORDER_PDF = `/api/delivery_order/pdf`

//PATCH
export const PATCH_UPDATE_DELIVERY_ORDER = GET_DELIVERY_ORDER_OF_INVOICE_PATH
export const PATCH_UPDATE_DELIVERY_ORDER_STATUS = GET_DELIVERY_ORDER_OF_INVOICE_PATH + '/status'

//DELETE



