import { RootState } from '@/store/store.config';
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { Tax, TaxListParams  } from '../types/tax.model';

export interface TaxState extends EntityState<Tax> {
  filter: TaxListParams;
}

export const taxAdapter = createEntityAdapter<Tax>({
  selectId: tax => tax.id,
});

export const taxInitialState: TaxState = taxAdapter.getInitialState({
  filter: {
    tax_type: 'tax_exclusive',
    query_term: '',
  },
});

const taxSlice = createSlice({
  name: 'tax',
  initialState: taxInitialState,
  reducers: {
    setFilter(state, action: PayloadAction<TaxListParams>) {
      state.filter = {
        ...state.filter,
        ...action.payload
      }
    },
    resetFilter(state, action: PayloadAction<TaxListParams | undefined>) {
      state.filter = action?.payload || taxInitialState.filter
    },
    resetAdvanceFilter(state, action: PayloadAction<TaxListParams | undefined>) {
      state.filter = {
        ...action?.payload || taxInitialState.filter,
        query_term: state.filter.query_term,
      }
    },
  },
});

// Actions
export const taxActions = taxSlice.actions;

export const selectTaxFilter = (state: RootState) => state.tax.filter;

// Reducer
const taxReducer = taxSlice.reducer;
export default taxReducer;
