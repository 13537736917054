// GET
export const GET_INVOICE_LIST = `/api/invoice`
export const GET_INVOICE = `/api/invoice/[invoice_id]`
export const GET_LINE_INVOICES_PATH = `/api/invoice/[invoice_id]/line_items`
export const GET_INVOICE_AUDIT_HISTORY_LIST_PATH = `/api/invoice/[invoice_id]/histories`
export const GET_EXPORT_INVOICE = `/api/invoice/export`
export const GET_NEXT_INVOICE_NO_PATH = `/api/invoice/next_number`

// POST
export const POST_CREATE_PATH = GET_INVOICE_LIST
export const POST_MAKE_PAYMENT_PATH = `/api/invoice/[invoice_id]/payments`
export const POST_UPDATE_STATUS_PATH = `/api/invoice/[invoice_id]/status`
export const POST_SEND_INVOICE_EMAIL = `/api/invoice/send_email`
export const POST_CREATE_INVOICE_PDF = `/api/invoice/pdf`
export const POST_CREATE_INVOICE_AUDITS= `/api/invoice/[invoice_id]/audits`

//PATCH
export const PATCH_UPDATE_PATH = GET_INVOICE

// DELETE
export const DELETE_INVOICE_PATH = `/api/invoice/[invoice_id]/destroy`
