'use client';

import { FC } from 'react';
import { Provider } from 'react-redux';

import { ComponentWithChildren } from '@/types/common/base';
import { store } from './store.config';

const StoreProvider: FC<ComponentWithChildren> = ({ children }) => {
  return (
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
        {children}
      {/* </PersistGate> */}
    </Provider>
  );
};

export default StoreProvider;
