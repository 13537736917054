import { IModalState } from './../types/modal.types';
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export const menuStateKey = 'v5-erp-menu-collapsed'

interface AppState {
  sidebarsCollapsed: boolean;
  isLogged: boolean,
  modals: IModalState
  modalPriority: string[]
}

const initialState: AppState = {
  sidebarsCollapsed: true,
  isLogged: false,
  modals: {},
  modalPriority: []
};

const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },

    /* Modal */
    setModalBackdrop(state, action: PayloadAction<string>) {
    },

    toggleModal(state, action: PayloadAction<IModalState>) {
      state.modals = {
        ...state.modals,
        ...action.payload,
      }
      state.modals = Object.fromEntries(Object.entries(state.modals).filter(([key, value]) => !!value))
      state.modalPriority = Object.entries(state.modals).reduce((arr, [key, value]) => !!value ? arr.concat(key) : arr, [] as string[])
    },

    resetModal(state) {
      state.modals = initialState.modals
      state.modalPriority = initialState.modalPriority
    },

    /* Sidebars */
    setSidebarCollapsed(state, action: PayloadAction<boolean>) {
      state.sidebarsCollapsed = action.payload;
    },
  },
});

export const sidebarsCollapsedSelector = ({ appState }: { appState: AppState }) =>
  appState.sidebarsCollapsed;
export const isLoggedSelector = ({ appState }: { appState: AppState }) =>
  appState.isLogged;

export const modalSelector = ({ appState }: { appState: AppState }, id: string) =>
  appState.modals[id];

export const isLatestModalSelector = ({ appState }: { appState: AppState }, id: string) =>
  appState.modalPriority.at(-1) === id;

export const modalIndexSelector = ({ appState }: { appState: AppState }, id: string) =>
  appState.modalPriority.findIndex(e => e === id);

export const { resetState, setSidebarCollapsed, toggleModal, resetModal, setModalBackdrop } =
  appStateSlice.actions;

export default appStateSlice.reducer;
