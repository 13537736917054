'use client';

import { FC } from 'react';

import CssBaseline from '@mui/material/CssBaseline';

import { ComponentWithChildren } from '@/types/common/base';
import { ThemeProvider } from '@mui/material';
import NextAppDirEmotionCacheProvider from './CacheProvider';
import theme from './lightThemeOptions';

const ThemeRegistry: FC<ComponentWithChildren> = ({ children }) => {
  return (
    <NextAppDirEmotionCacheProvider options={{ key: 'mui' }}>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {children}
      </ThemeProvider>
    </NextAppDirEmotionCacheProvider>
  )
};
export default ThemeRegistry;
