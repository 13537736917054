import appSlice from "@/features/App/slices/app.slice";
import authReducer from "@/features/Auth/slices/auth.slice";
import { authApiSlice } from "@/features/Auth/slices/authApi.slice";
import { categoryApiSlice } from "@/features/Catalog/slices/categoryApi.slice";
import productVariantReducer from "@/features/Catalog/slices/productVariant.slice";
import { productVariantApiSlice } from "@/features/Catalog/slices/productVariantApi.slice";
import { stockApiSlice } from "@/features/Catalog/slices/stockApi.slice";
import { configApiSlice } from "@/features/Configuration/slices/configurationApi.slice";
import deliveryOrderReducer from "@/features/DeliveryOrders/slices/deliveryOrder.slice";
import { deliveryOrderApiSlice } from "@/features/DeliveryOrders/slices/deliveryOrderApi.slice";
import invoiceReducer from "@/features/Invoices/slices/invoice.slice";
import { invoiceApiSlice } from "@/features/Invoices/slices/invoiceApi.slice";
import { customerApiSlice } from "@/features/Marketing/slices/customerApi.slice";
import { paymentMethodApi } from "@/features/Operation/slices/paymentMethodApi.slice";
import { staffApiSlice } from "@/features/Operation/slices/staffApi.slice";
import { storeApiSlice } from "@/features/Operation/slices/storeApi.slice";
import { taxRuleApiSlice } from "@/features/Operation/slices/taxRuleApi.slice";
import quotationReducer from "@/features/Quotations/slices/quotation.slice";
import { quotationApiSlice } from "@/features/Quotations/slices/quotationApi.slice";
import taxReducer from "@/features/Taxes/slices/tax.slice";
import { taxApiSlice } from "@/features/Taxes/slices/taxApi.slice";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { dateMiddleware } from "./dateMiddleware.store";
import { rtkQueryErrorLogger } from "./errorLogger.middleware";
import activityReducer from "@/features/Activities/slices/activity.slice";
import { activityApiSlice } from "@/features/Activities/slices/activityApi.slice";
import dashboardReducer from "@/features/Dashboard/slices/dashboard.slice";
import { dashboardApiSlice } from "@/features/Dashboard/slices/dashboardApi.slice";

// const persistConfig: PersistConfig<any> = {
//   key: 'app_storage',
//   version: 1.1,
//   storage,
//   debug: true,
//   whitelist: [],
// }

const combinedReducer = combineReducers({
  appState: appSlice,
  dashboard: dashboardReducer,
  quotation: quotationReducer,
  invoice: invoiceReducer,
  deliveryOrder: deliveryOrderReducer,
  productVariant: productVariantReducer,
  tax: taxReducer,
  activity: activityReducer,
  auth: authReducer,
  [authApiSlice.reducerPath]: authApiSlice.reducer,
  [dashboardApiSlice.reducerPath]: dashboardApiSlice.reducer,
  [storeApiSlice.reducerPath]: storeApiSlice.reducer,
  [staffApiSlice.reducerPath]: staffApiSlice.reducer,
  [categoryApiSlice.reducerPath]: categoryApiSlice.reducer,
  [taxRuleApiSlice.reducerPath]: taxRuleApiSlice.reducer,
  [customerApiSlice.reducerPath]: customerApiSlice.reducer,
  [productVariantApiSlice.reducerPath]: productVariantApiSlice.reducer,
  [stockApiSlice.reducerPath]: stockApiSlice.reducer,
  [quotationApiSlice.reducerPath]: quotationApiSlice.reducer,
  [invoiceApiSlice.reducerPath]: invoiceApiSlice.reducer,
  [deliveryOrderApiSlice.reducerPath]: deliveryOrderApiSlice.reducer,
  [configApiSlice.reducerPath]: configApiSlice.reducer,
  [taxApiSlice.reducerPath]: taxApiSlice.reducer,
  [activityApiSlice.reducerPath]: activityApiSlice.reducer,
  [paymentMethodApi.reducerPath]: paymentMethodApi.reducer
})

// const persistedReducer = persistReducer(persistConfig, combinedReducer)

export const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: combinedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, //Only turn off this when having dateMiddleware
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
    }).concat(
      dateMiddleware,
      rtkQueryErrorLogger,
      authApiSlice.middleware,
      dashboardApiSlice.middleware,
      storeApiSlice.middleware,
      staffApiSlice.middleware,
      categoryApiSlice.middleware,
      taxApiSlice.middleware,
      productVariantApiSlice.middleware,
      stockApiSlice.middleware,
      customerApiSlice.middleware,
      quotationApiSlice.middleware,
      invoiceApiSlice.middleware,
      deliveryOrderApiSlice.middleware,
      configApiSlice.middleware,
      taxApiSlice.middleware,
      activityApiSlice.middleware,
      paymentMethodApi.middleware
    ),
})

setupListeners(store.dispatch)

// export const persistor = persistStore(store)

export type AppStore = typeof store;
export type RootState = ReturnType<typeof combinedReducer>;
export type AppDispatch = AppStore["dispatch"];

