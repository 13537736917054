import { DATE_FORMAT } from '@/components/formFields/DateTimeField/DateTimeField.constants';
import { TDiscountType, LineItemValues } from '@/features/Catalog/types/stock.model';
import { TaxAttributes } from '@/features/Operation/types/tax.model';
import { isMatch } from 'date-fns';
import * as yup from 'yup';
import { DeliveryOrder } from './deliveryOrder.model';
import { LineDeliveryOrder } from './lineDeliveryOrder.model';
import { RecordData, SinglePayload } from '@/types/common/baseReqRes';
import { qtyBigNumber } from '@/utils/bigNumber';

export const DeliveryOrderCreateFields = {
  id: 'id',
  deliveryOrderRef: 'delivery_order_ref',
  invoiceId: 'invoice_id',
  invoiceNo: 'invoice_no',
  status: 'status',
  storeName: 'store_name',
  address: 'address',
  customerName: 'customer_name',
  contactInfo: 'contact_info',
  contactNo: 'contact_no',
  scheduleAt: 'schedule_at',
  scheduleTimeFrom: 'schedule_time_from',
  scheduleTimeTo: 'schedule_time_to',
  dueDate: 'due_date',
  deliveryOrderItemList: 'delivery_order_item_list',
  notes: 'notes',
  shippingFee: 'shipping_fee',
  sendEmailInfo: 'send_email_info'
} as const;

export const SendEmailFields = {
  customerEmail: 'customer_email',
  contactEmail: 'contact_email',
  emailContent: 'email_content'
} as const;

export const MakePaymentFields = {
  //Delivery Detail
  customerName: 'customer_name',
  deliveryOrderAmount: 'delivery_order_amount',
  outstandingAmount: 'outstanding_amount',

  //Payment Detail
  paymentAmount: 'payment_amount',
  paymentType: 'payment_type',
  paymentDate: 'payment_date',
  receiptHeader: 'receipt_header'
} as const;

export const DeliveryOrderItemListFields = {
  id: 'id',
  ordered: 'ordered',
  notes: 'notes',
  quantity: 'quantity',
  remaining: 'remaining',
  uom: 'uom'
} as const;

export const TaxRuleListFields = {
  id: 'id',
  taxInfo: 'tax_info',
  rowAmount: 'row_amount',
  taxAmount: 'tax_amount',
  peerTaxAmount: 'peer_tax_amount'
} as const;

export const DiscountRuleListFields = {
  id: 'id',
  discountType: 'discount_type',
  discountAmount: 'discount_amount',
  rowDiscountTotal: 'row_discount_total'
} as const;

export interface DeliveryOrderDto extends DeliveryOrder {
  [DeliveryOrderCreateFields.customerName]: string;
  [DeliveryOrderCreateFields.invoiceId]: string;
  [DeliveryOrderCreateFields.storeName]: string;
  [DeliveryOrderCreateFields.deliveryOrderItemList]: (Partial<LineItemValues> & LineDeliveryOrder)[];
  [DeliveryOrderCreateFields.sendEmailInfo]?: SendEmailInfo;
}

export interface CreateDeliveryOrderRecord
  extends RecordData<Omit<DeliveryOrderDto, 'delivery_order_item_list'>, 'invoice_delivery_orders'> {}

export interface SerializedLineDeliveryOrder extends RecordData<LineDeliveryOrder, 'invoice_line_deliveries'> {}

export interface SerializedDeliveryOrder
  extends SinglePayload<CreateDeliveryOrderRecord, SerializedLineDeliveryOrder> {}

export const defaultDeliveryOrderCreateFields: DeliveryOrderDto = {
  [DeliveryOrderCreateFields.id]: '',
  [DeliveryOrderCreateFields.invoiceId]: '',
  [DeliveryOrderCreateFields.status]: 'pending',
  [DeliveryOrderCreateFields.storeName]: '',
  [DeliveryOrderCreateFields.address]: '',
  [DeliveryOrderCreateFields.customerName]: '',
  [DeliveryOrderCreateFields.contactInfo]: '',
  [DeliveryOrderCreateFields.contactNo]: '',
  [DeliveryOrderCreateFields.deliveryOrderRef]: '',
  [DeliveryOrderCreateFields.invoiceNo]: '',
  [DeliveryOrderCreateFields.scheduleAt]: null,
  [DeliveryOrderCreateFields.scheduleTimeFrom]: 0,
  [DeliveryOrderCreateFields.scheduleTimeTo]: 1439,
  [DeliveryOrderCreateFields.deliveryOrderItemList]: [],
  [DeliveryOrderCreateFields.notes]: '',
  [DeliveryOrderCreateFields.sendEmailInfo]: {
    [SendEmailFields.customerEmail]: '',
    [SendEmailFields.contactEmail]: '',
    [SendEmailFields.emailContent]: ''
  }
};

export interface SendEmailInfo {
  [SendEmailFields.contactEmail]: string;
  [SendEmailFields.customerEmail]: string;
  [SendEmailFields.emailContent]: string;
}

export interface LineItemFormField extends LineDeliveryOrder {}

export interface TaxRuleFormField {
  [TaxRuleListFields.id]?: string;
  [TaxRuleListFields.taxInfo]: TaxAttributes | Partial<TaxAttributes> | null;
  [TaxRuleListFields.rowAmount]: number | string;
  [TaxRuleListFields.taxAmount]: number | string;
  [TaxRuleListFields.peerTaxAmount]: number | string;
}

export interface DiscountRuleFormField {
  [DiscountRuleListFields.id]?: string;
  [DiscountRuleListFields.discountType]: TDiscountType;
  [DiscountRuleListFields.discountAmount]: number | string;
  [DiscountRuleListFields.rowDiscountTotal]: number | string;
}

const REQUIRED_MSG = ' is required';
const DATE_FORMAT_ERROR_MSG = 'Date is not match with format dd/MM/yyyy';
const DATE_CHAR_LENGTH_MSG = ' must be at most 20 characters';
const MIN_LIST_MSG = 'Need at least 1 ';
const TIME_RANGE_MSG = 'Schedule Time From must be Less than Schedule Time To';

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

export const DeliveryOrderCreateFromSchema = yup.object().shape({
  [DeliveryOrderCreateFields.id]: yup.string().optional(),
  [DeliveryOrderCreateFields.storeName]: yup.string().optional(),
  [DeliveryOrderCreateFields.customerName]: yup.string().optional(),
  [DeliveryOrderCreateFields.deliveryOrderRef]: yup
    .string()
    .label('Delivery Order Number')
    .required('DeliveryOrder No' + REQUIRED_MSG)
    .max(50),
  [DeliveryOrderCreateFields.contactInfo]: yup
    .string()
    .label('Contact Person')
    .max(150),
  [DeliveryOrderCreateFields.contactNo]: yup
    .string()
    .label("Contact Number")
    .max(20),
  [DeliveryOrderCreateFields.invoiceNo]: yup.string().optional().max(50),
  [DeliveryOrderCreateFields.scheduleAt]: yup
    .string()
    .required('Schedule Date' + REQUIRED_MSG)
    .max(20, 'Schedule Date' + DATE_CHAR_LENGTH_MSG)
    .test('Date format', (value, ctx) => {
      if (!value) return false;
      return isMatch(value, DATE_FORMAT) || ctx.createError({ message: DATE_FORMAT_ERROR_MSG });
    }),

  //Delivery Item List
  [DeliveryOrderCreateFields.deliveryOrderItemList]: yup.lazy(() =>
    yup
      .array()
      .of(
        yup.object().shape({
          [DeliveryOrderItemListFields.id]: yup.string().label('ID').required(),
          [DeliveryOrderItemListFields.uom]: yup.string().label('UOM').max(5),
          [DeliveryOrderItemListFields.quantity]: yup
            .number()
            .label('Quantity')
            .transform((val) => +val)
            .min(0)
            .required(REQUIRED_MSG)
            .test("validate quantity", "Larger than Remaining", (value, ctx) => {
              const { parent } = ctx
              const { remaining_quantity } = parent as LineDeliveryOrder
              return qtyBigNumber(remaining_quantity).comparedTo(qtyBigNumber(value)) >= 0
            })
        })
      )
      .when(DeliveryOrderCreateFields.storeName, (fieldValues: string[], field, deliveryOrderItemList) => {
        const storeName = fieldValues[0];
        if (storeName && deliveryOrderItemList.value?.length <= 0) {
          return field.test('Validation min of list', (val, ctx) =>
            ctx.createError({ message: MIN_LIST_MSG + 'product' })
          );
        }

        return field;
      })
  ),
  [DeliveryOrderCreateFields.scheduleTimeTo]: yup.number().required(REQUIRED_MSG),
  [DeliveryOrderCreateFields.scheduleTimeFrom]: yup
    .number()
    .test('Valid time range', TIME_RANGE_MSG, (value, context) => {
      if (value === undefined) return true

      const scheduleTimeTo: number = context.parent[DeliveryOrderCreateFields.scheduleTimeTo];
      return scheduleTimeTo > value;
    })
});

export const deliveryOrderSearchSchema = yup.object().shape({
  search_field: yup.string(),
  delivery_order_start_date: yup
    .date()
    .nullable()
    .test('start-before-end', 'DeliveryOrder Start date must be before End date', function (value) {
      const { delivery_order_end_date } = this.parent;
      if (!value || !delivery_order_end_date) {
        return true;
      }
      return value < delivery_order_end_date;
    }),
  delivery_order_end_date: yup
    .date()
    .nullable()
    .test('end-after-start', 'DeliveryOrder End date must be after Start date', function (value) {
      const { delivery_order_start_date } = this.parent;
      if (!value || !delivery_order_start_date) {
        return true;
      }
      return delivery_order_start_date < value;
    }),
  due_start_date: yup
    .date()
    .nullable()
    .test('start-before-end', 'Due Start date must be before End date', function (value) {
      const { due_end_date } = this.parent;
      if (!value || !due_end_date) {
        return true;
      }
      return value < due_end_date;
    }),
  due_end_date: yup
    .date()
    .nullable()
    .test('end-after-start', 'Due End date must be after Start date', function (value) {
      const { due_end_date } = this.parent;
      if (!value || !due_end_date) {
        return true;
      }
      return due_end_date < value;
    })
});
