export const PrimaryColors = {
  Primary90: '#0E2761',
  Primary70: '#0E2761',
  Primary50: '#19377B',
  Primary30: '#0F42A1',
  Primary10: '#2C5CCB',
  Primary05: '#9CB9FC',
  Primary00: '#BFD3FF'
};

export const SecondaryColors = {
  Secondary90: '#0E4300',
  Secondary70: '#386A07',
  Secondary50: '#629435',
  Secondary30: '#68C733',
  Secondary10: '#A3D572',
  Secondary05: '#AEEA73'
};

export const VariantColors = {
  Variant90: '#171C28',
  Variant80: '#2F3442',
  Variant60: '#4D5362',
  Variant30: '#707888',
  Variant20: '#949DAF',
  Variant10: '#A6AFC2',
  Variant05: '#C1CCE7',
  Variant00: '#E5EBF4',
  Variant0: '#CFDAEC'
};

export const ErrorColors = {
  Error90: '#88000A',
  Error80: '#A1001C',
  Error60: '#D3353E',
  Error30: '#FF5F5F',
  Error20: '#FF928C',
  Error10: '#FFCBCB'
};

export const WarningColors = {
  Warning90: '#792E0D',
  Warning80: '#93370D',
  Warning60: '#DC6803',
  Warning30: '#FEC84B',
  Warning20: '#FEDF89',
  Warning10: '#FEEFC7'
};

export const SuccessColors = {
  Success90: '#004709',
  Success80: '#005B1C',
  Success60: '#008441',
  Success30: '#05944F',
  Success20: '#0BB663',
  Success05: '#BCF1CF',
};

export const DisabledColors = {
  Disabled30: '#707888',
  Disabled20: '#949daf',
  Disabled60: '#4d5362',
  Disabled10: '#b6bece',
  Disabled05: '#ccd2de'
};

export const BackgroundColors = {
  Background20: '#C6C6C6',
  Background10: '#DDDDDD',
  Background05: '#F0F2F5',
  Background00: '#FFFFFF'
};

export const pressedColor = '#E4EDFF'

export const baseInputColor = {
  backgroundColor: "#F4F5F8"
}

export const logoPreviewColor = {
  backgroundColor: "#ECEEF1",
  color: "#737373"
}
