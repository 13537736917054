"use client";

import type { ToastContainerProps } from "react-toastify";
import ToastCloseButton from "./ToastCloseButton";
import StyledToastWrapper from "./ToastContainer.styles";
import { ToastContainer as Toast } from "react-toastify";
import { Slide } from "react-toastify";

const ToastContainer = ({ theme, ...props }: ToastContainerProps) => {
  return (
    <StyledToastWrapper>
      <Toast
        {...props}
        closeButton={ToastCloseButton}
        transition={Slide}
      />
    </StyledToastWrapper>
  );
};

export default ToastContainer;
